import React, { useState } from 'react';

import { apiRequest } from '../../../lib/request';


function AddEmailContact() {

	const [email, setEmail] = useState<string>('');
	const [emailAddSuccess, setEmailAddSuccess] = useState<boolean>(false);
	const [emailAddError, setEmailAddError] = useState<string>('');

	return (
		<div className="add-form">
			<div className="add-form__title">
				Добавить новый E-mail
			</div>
			{ emailAddSuccess ? 
				<>
					На указанный E-mail отправлена ссылка для подтверждения.
				</>
				:
				<div className="add-form__fields">
					{ emailAddError && <>
						{ emailAddError }
					</>}
					<div className="site-edit__name">
						<input type="text" name="email" value={ email } onChange={ onChangeEmailHandler } />
					</div>
					<div className="site-edit__save">
						<button onClick={ onClickAddHandler }>Продолжить</button>
					</div>
				</div>
			}
		</div>
	);


	function onChangeEmailHandler(e: { target: { name: string; value: string; }; }) {

		setEmailAddError('');
		setEmail(e.target.value);
	}


	async function onClickAddHandler() {

		setEmailAddError('');

		try {

			const requestData = {
				email: email,
			}

			const response = await apiRequest('/contact/add-email/', requestData);

			if(response?.data?.result?.success) {

				setEmailAddSuccess(true);

			} else if(response?.data?.result?.error) {

				setEmailAddError(response.data.result.error);
			}

			console.log(response);

		} catch (error) {

			console.error(error);
		}
	}
}


export default AddEmailContact;