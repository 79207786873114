import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './core/store/store';

import { Toaster } from 'sonner';

import './assets/css/reboot.min.css';
import './assets/css/style_auth.css';
import './assets/css/style.css';

import PublicRoute from './core/routing/PublicRoute';
import NotAuthorizedRoute from './core/routing/NotAuthorizedRoute';
import PrivateRoute from './core/routing/PrivateRoute';

// Pages

import Page404 from './core/pages/404';

import Policy from './core/pages/public/policy';

import ConfirmUser from './core/pages/user/confirm';

import ConfirmContactEmail from './core/pages/contacts/confirm-email';

import Login from './core/pages/auth/login';
import Registration from './core/pages/auth/registration';
import RestorePassword from './core/pages/auth/restore';
import ChangePassword from './core/pages/auth/change';

import PageMain from './core/pages/main';

import PageSitesList from './core/pages/sites/list';
import PageSiteAdd from './core/pages/sites/add';
import PageSiteEdit from './core/pages/sites/edit';

import PageContactsList from './core/pages/contacts/list';
import PageContactEdit from './core/pages/contacts/edit';


function App() {
	return (
		<Provider store={ store }>
			<div className="App">
				<BrowserRouter>
					<Routes>
						<Route element={ <PublicRoute /> }>
							<Route path="/confirm/" element={ <ConfirmUser/> }/>
							<Route path="/confirm-email/" element={ <ConfirmContactEmail/> }/>
							<Route path="/policy/" element={ <Policy/> }/>
							<Route path="*" element={ <Page404/> } />
						</Route>
						<Route element={ <NotAuthorizedRoute /> }>
							<Route path="/login/" element={ <Login/> }/>
							<Route path="/registration/" element={ <Registration/> }/>
							<Route path="/restore/" element={ <RestorePassword/> }/>
							<Route path="/change/" element={ <ChangePassword/> }/>
						</Route>
						<Route element={ <PrivateRoute /> }>
							<Route path="/" element={ <PageMain/> } />

							<Route path="/sites/" element={ <PageSitesList/> } />
							<Route path='/sites/add/' element={ <PageSiteAdd/> }/>
							<Route path='/sites/:id/' element={ <PageSiteEdit/> }/>

							<Route path="/contacts/" element={ <PageContactsList/> } />
							<Route path='/contacts/:id/' element={ <PageContactEdit/> }/>

						</Route>
					</Routes>
				</BrowserRouter>
				<Toaster expand={ true } richColors closeButton toastOptions={{
					classNames: {
						closeButton: 'toaster__close-btn',
					}
 				 }}/>
			</div>
		</Provider>
	);
}

export default App;
