import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { apiRequest } from '../../lib/request';

import { setPageTitle } from '../../store/page/page.slice';

import AddEmailContact from './add/email';
import AddTelegramContact from './add/telegram';

import { motion } from "framer-motion";


function PageContactsList() {

	const [isLoading, setIsLoading] = useState<boolean>(true);
	// const [arContscts, setArContscts] = useState<[] | undefined>(undefined);

	const [mainEmail, setMainEmail] = useState<string|undefined>(undefined);
	const [contactsEmail, setContactsEmail] = useState<[]|undefined>(undefined);
	const [contactsTelegram, setContactsTelegram] = useState<[]|undefined>(undefined);
	
	const dispatch = useDispatch();

	useEffect(() => {
		
		dispatch(setPageTitle('Адреса уведомлений'));
		getContactsList();

	}, [dispatch]);


	return (
		<>
			{
				isLoading
				?
				<>
					Loading
				</>
				:
				<>
					{
						mainEmail
						&&
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
							<div>
								<div className="title">Основной E-mail:</div>
								<div className="list">
									<div className="item">
										{ mainEmail }
									</div>
								</div>
							</div>
							<br />
						</motion.div>
					}
					{
						contactsEmail
						&&
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
							{ contactsEmail
								&&
								<div>
									<div className="title">E-mail:</div>
									<div className="list">
										{
											contactsEmail.map(
												(contactsEmailItem:any) => <div className="item" key={ contactsEmailItem.ID }>
													{ contactsEmailItem.VALUE }{ (!contactsEmailItem.CONFIRMED || contactsEmailItem.CONFIRMED === '0') && <span  onClick={ (e) => { onClickResendHandler(contactsEmailItem.VALUE); } }> * </span> } <span  onClick={ (e) => { onClickDeleteHandler(contactsEmailItem.ID); } }> x </span>
													<Link to={'/contacts/' + contactsEmailItem.ID + '/'}>edit</Link>
												</div>
											)
										}
									</div>
								</div>
							}
							<br />
						</motion.div>
					}
					{
						contactsTelegram
						&&
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
							{ contactsTelegram
								&&
								<div>
									<div className="title">Telegram:</div>
									<div className="list">
										{
											contactsTelegram.map(
												(contactsTelegramItem:any) => <div className="item" key={ contactsTelegramItem.ID }>
													{ contactsTelegramItem.NAME } <span  onClick={ (e) => { onClickDeleteHandler(contactsTelegramItem.ID); } }> x </span>
													<Link to={'/contacts/' + contactsTelegramItem.ID + '/'}>edit</Link>
												</div>
											)
										}
									</div>
								</div>
							}
							<br />
						</motion.div>
					}
					<div className="add">
						<div className="add-link">
							Добавить E-mail
						</div>
						<div className="add-link">
							Добавить Telegram
						</div>
					</div>
					<hr />
					<AddEmailContact />
					<hr />
					<AddTelegramContact />
				</>
			}
		</>
	);


	async function getContactsList() {

		try {

			const response = await apiRequest('/contact/get-list/');
			const result = response?.data?.result;
			
			setMainEmail(result?.MAIN_EMAIL);
			setContactsEmail(result?.EMAIL);
			setContactsTelegram(result?.TELEGRAM);

		} catch(error) {

			console.error(error);

		} finally {

			setTimeout(() => {

				setIsLoading(false);

			}, 100);
		}
	}


	async function onClickResendHandler(email:string) {

		try {

			const requestData = {
				email: email,
			}

			const response = await apiRequest('/contact/resend-email-confirm-code/', requestData);

			if(response?.data?.result?.success) {

				alert('Ok!');

			} else if(response?.data?.result?.errors) {

				
			}

			console.log(response);

		} catch (error) {

			console.error(error);
		}
	}


	async function onClickDeleteHandler(id:number) {

		try {

			const requestData = {
				id: id,
			}

			const response = await apiRequest('/contact/delete-contact/', requestData);
			const result = response?.data?.result;

			setMainEmail(result?.MAIN_EMAIL);
			setContactsEmail(result?.EMAIL);
			setContactsTelegram(result?.TELEGRAM);

			if(response?.data?.result?.success) {

				

			} else if(response?.data?.result?.errors) {

				
			}

			console.log(response);

		} catch (error) {

			console.error(error);
		}
	}


	
}


export default PageContactsList;
