import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Navigate, useLocation } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { contactScheduleInterface } from '../../interfaces/contact-schedule';
import { setPageTitle } from '../../store/page/page.slice';

import { apiRequest } from '../../lib/request';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Switch, Input, Checkbox } from '@mui/material';


import { motion } from 'framer-motion';

function PageContactEdit() {

	const [formData, setFormData] = useState<contactScheduleInterface>({
        ACTIVE: null,
		AVAILABILITY: null,
		FIRST: null,
		REPEATED: null,
		RESTORE: null,
		SSL: null,
		DOMAIN: null,
    });

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [schedule, setSchedule] = useState<contactScheduleInterface | null>(null);

	const [isSaving, setIsSaving] = useState<boolean>(false);

	const [isNeedSave, setNeedSave] = useState<boolean>(false);

	const { id } = useParams();

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {

		getContact(id);

		function getContact(contactId:string|undefined) {

			if(contactId === undefined) {
	
				return;
			}
	
			const response = apiRequest('/contact/get/' + contactId + '/');
	
			response.then(response => {
	
				setSchedule(response.data.result);
				setFormData(response.data.result);

				//dispatch(setPageTitle(response.data.result.NAME));
				
			}).catch(error => {
		
				console.error(error);
	
			}).finally(() => {
	
				window.setTimeout(() => {
	
					setIsLoading(false);
	
				}, 50);
			});
		}
		
	}, [id, dispatch]);


	const handleChange = (e: { target: { name: any; value: any; type: any; checked?: any; }; }) => {
		const { name, value, type, checked } = e.target;
		const updatedFormData = {
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		};

		setFormData(updatedFormData);
		
		saveForm(updatedFormData);

		console.log(updatedFormData);
	};

	const handleChangeRepeated = (event: React.ChangeEvent<HTMLInputElement>, index:number) => {

		if(!formData.REPEATED) {
			
			return;
		}

		const updatedFormData = {
			...formData,
			REPEATED: formData.REPEATED.map((value, i) => i === index ? event.target.value : value),
		};

		setFormData(updatedFormData);

		setNeedSave(true);
	};

	const handleBlurRepeated = () => {

		if(!isNeedSave) {
			
			return;
		}

		setNeedSave(false);

		saveForm(formData);
	};

	const handleDeleteRepeatedItem = (index:number) => {

		if(!formData.REPEATED) {
			
			return;
		}

		const updatedFormData = {
			...formData,
			REPEATED: formData.REPEATED.filter((value, i) => i !== index),
		};

		setFormData(updatedFormData);
		saveForm(updatedFormData);
	};

	const handleAddRepeatedItem = () => {

		const newRepeated = formData.REPEATED ? [...formData.REPEATED, ''] : [''];

		const updatedFormData = {
			...formData,
			REPEATED: newRepeated,
		};

		setFormData(updatedFormData);
	};


	async function saveForm(formData: contactScheduleInterface) {

		try {

			if(isSaving) {

				return;
			}

			setIsSaving(true);

			const response = await apiRequest('/contact/save-schedule/', formData);

			if(response?.data?.result) {

				setFormData(response.data.result);

				console.log(response.data.result);

			} else if(response?.data?.result?.error) {

				
			}

		} catch (error) {

			console.error(error);

		} finally {

			setIsSaving(false);
		}

	}

	return (
		isLoading ? (
			<>
				<Skeleton count={ 10 }/>
			</>
		) : (
			<motion.div initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.2 }}>
					{
						schedule && <>
							<div className="schedule__edit">
								<div className="schedule__edit-item">
									<label>
										<Switch checked={ formData.ACTIVE ?? true } onChange={ handleChange } name="ACTIVE" disabled={ isSaving } />
										Активность (да/нет)
									</label>
								</div>
								{ formData.ACTIVE && <>
									<div className="schedule__edit-item">
										<label>
											<Checkbox checked={ formData.AVAILABILITY ?? true } onChange={ handleChange } name="AVAILABILITY" disabled={ isSaving } />
											Доступность (да/нет)
										</label>
									</div>
									{ formData.AVAILABILITY && <>
										<div className="schedule__edit-item">
											Первое уведомления об ошибке (нет/сразу/после N-й ошибки)
											<br />
											<input type="text" name="FIRST" disabled={ isSaving } value={ formData.FIRST ? formData.FIRST : '' } onChange={ handleChange } />
										</div>
										<div className="schedule__edit-item">
											Повоторные уведомления об ошибке (нет/через N минут/каждую N-ю минуту)
											{ formData.REPEATED && <>
												{
													formData.REPEATED.map(
														(repeated:string, index:number) => <div className="item" key={ index }>
															<input type="text" name="REPEATED[]" disabled={ isSaving } value={ repeated ? repeated : '' } onChange={ (e) =>  handleChangeRepeated(e, index) } onBlur={ (e) =>  handleBlurRepeated() } /> <span onClick={ (e) =>  handleDeleteRepeatedItem(index) }>x</span>
														</div>
													)
												}
											</> }
											<span onClick={ handleAddRepeatedItem } >+</span>
										</div>
										<div className="schedule__edit-item">
											<label>
												<Checkbox checked={ formData.RESTORE ?? true } onChange={ handleChange } name="RESTORE" disabled={ isSaving } />
												Уведомление о восстановлении работы (да/нет)
											</label>
										</div>
									</> }
									<div className="schedule__edit-item">
										<label>
											<Checkbox checked={ formData.SSL ?? true } onChange={ handleChange } name="SSL" disabled={ isSaving } />
											Уведомление о проблеме с SSL сертификатом (да/нет)
										</label>
									</div>
									<div className="schedule__edit-item">
										<label>
											<Checkbox checked={ formData.DOMAIN ?? true } onChange={ handleChange } name="DOMAIN" disabled={ isSaving } />
											Уведомление о проблеме с доменом (да/нет)
										</label>
									</div>
								</> }
							</div>
						</>
					}
			</motion.div>
		)
	);

	

}




export default PageContactEdit;
