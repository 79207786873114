import React from 'react';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { apiRequest } from '../../lib/request';

import { siteInterface } from '../../interfaces/site.interface';

import { useDispatch } from 'react-redux';
import { setPageTitle } from '../../store/page/page.slice';

import { motion } from "framer-motion";

import { Radio } from '@mui/material';

function PageSiteAdd() {

	const [newSiteId, setNewSiteId] = useState<number|undefined>(undefined);

	const [siteName, setSiteName] = useState<string>('');
	const [arVariants, setArVariants] = useState<Array<any>|undefined>(undefined);
	const [selectedVariant, setSelectedVariant] = useState<string|undefined>(undefined);
	const [variantsError, setVariantsError] = useState<string|undefined>(undefined);



	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {

		dispatch(setPageTitle('Добавить сайт'));
	});

	if(newSiteId) {

		return <Navigate to={ '/sites/' + newSiteId + '/' } state={{ from: location }} replace />;
	}

	return (
		<motion.div initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.2 }}>
			<div className="site-edit__form">
				<div className="site-edit__name">
					<input type="text" name="name" value={ siteName } onChange={ onChangeSiteNameHandler } />
				</div>
				<div className="site-edit__save">
					<button onClick={ onClickAddHandler }>Продолжить</button>
				</div>
			</div>

			<div className="variants-list">
				{ arVariants
					?
					<>
					{
						arVariants.map(
							(variantData: any) => <div key={ variantData.url }>
								<label>
									<Radio size="small" name="variant" value={ variantData.url } checked={ selectedVariant === variantData.url ? true : false } onChange={ onChangeSiteVariantHandler } />
									{ variantData.url }
									{ variantData.domainDisplay } 
									{ variantData.http_code }
									{ variantData.redirectUrl ? ' -> ' + variantData.redirectUrl : '' }
									{ variantData.isCloudflare ? ' Cloudflare' : '' }
								</label>
							</div>
						)
					}
					<div className="site-edit__save">
						<button onClick={ onClickAddSaveHandler }>Сохранить</button>
					</div>
					</>
					:
					<></>
				}
			</div>

		</motion.div>
	);


	async function onClickAddHandler() {

		try {

			const requestData = {
				name: siteName,
			}

			setArVariants(undefined);
			setVariantsError(undefined);
			setSelectedVariant(undefined);

			const response = await apiRequest('/site/add/', requestData);

			if(response?.data?.result?.variants) {

				setArVariants(response.data.result.variants);
				setSelectedVariant(response.data.result.variants[0].url);

				console.log(response.data.result.variants[0].url);

			} else if(response?.data?.result?.error) {

				setVariantsError(response.data.result.error);
			}

			console.log(response);

		} catch (error) {

			console.error(error);
		}
	}


	async function onClickAddSaveHandler() {

		try {

			const requestData = {
				name: siteName,
				url: selectedVariant,
			}

			const response = await apiRequest('/site/addSave/', requestData);

			if(response?.data?.result?.success) {

				setNewSiteId(response?.data?.result?.id);

			} else if(response?.data?.result?.error) {

				console.log(response.data.result);
			}

		} catch (error) {

			console.error(error);
		}
	}


	function onChangeSiteNameHandler(e: { target: { name: string; value: string; }; }) {

		setArVariants(undefined);
		setVariantsError(undefined);
		setSelectedVariant(undefined);

		setSiteName(e.target.value);
	}

	function onChangeSiteVariantHandler(e: { target: { name: string; value: string; }; }) {

		setSelectedVariant(e.target.value);
	}

	

}

export default PageSiteAdd;
