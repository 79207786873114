import React, { useState } from 'react';

import { apiRequest } from '../../../lib/request';

function AddTelegramContact() {

	const [tgContact, setTgContact] = useState<undefined|any>(undefined);
	const [tgContactError, setTgContactError] = useState<string>('');

	return (
		<div className="add-form">
			<div className="add-form__title">
				Добавить новый Telegram
			</div>
			<div className="add-form__fields">
				<div className="site-edit__save">
					<button onClick={ onClickAddHandler }>Добавить</button>
				</div>
			</div>
			{ tgContactError && <div className="add-form__error">{ tgContactError }</div> }
			{ tgContact && <div className="add-form__error">
				Запустите бота по <a href={ tgContact.link } target="_blank">ссылке</a>
				<br />
				Или найдите бота <b>{ tgContact.bot }</b> по поиску и отправьте ему код: <b>{ tgContact.code }</b>
			</div> }
		</div>
	);


	async function onClickAddHandler() {

		setTgContact(undefined);
		setTgContactError('');

		try {

			const response = await apiRequest('/contact/add-telegram/', {});

			if(response?.data?.result?.success) {

				setTgContact(response.data.result.data);

			} else if(response?.data?.result?.error) {

				setTgContactError(response.data.result.error);
			}

			console.log(response);

		} catch (error) {

			console.error(error);
		}
	}
}


export default AddTelegramContact;